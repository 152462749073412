import React from 'react'
import { Link } from "react-router-dom";
import './footer.scss';
import 'font-awesome/css/font-awesome.min.css';

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-4 box">
                        <span className="copyright quick-links">Copyright &copy; Capanions 2025
                        </span>
                    </div>
                    <div className="col-md-4 box">
                        <ul className="list-inline social-buttons">
                            <li className="list-inline-item">
                                <a href="https://twitter.com/Capanions_NFT">
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </li>
                            {/* <li className="list-inline-item">
                                <a href="#">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li> */}
                        </ul>
                    </div>
                    <div className="col-md-4 box">
                        <ul className="list-inline quick-links">
                            <li className="list-inline-item">
                                <a href="#">Smart Contract</a>
                            </li>
                            <li className="list-inline-item">
                            <   Link to="/terms">Terms</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
